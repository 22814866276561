<template>
    <div v-if="item">
        <v-icon :color="getStatusColor(item)">fiber_manual_record</v-icon>
        {{ item }}
    </div>
</template>

<script>
export default {
    data: () => ({
        group: null,
        item: null,
        preadviseStatuses: [
            {
                text: 'Confirmed',
                value: 'confirmed',
                color: 'green'
            },
            {
                text: 'Error',
                value: 'error',
                color: 'red'
            },
            {
                text: 'Pending',
                value: 'pending',
                color: 'orange'
            },
            {
                text: 'Submitted',
                value: 'submitted',
                color: 'blue'
            },
        ]
    }),
    mounted() {
        this.item = this.params.value;
        if (this.params.node && this.params.node.group && this.params.node.key) {
            this.group = this.params.value;
        }
    },
    methods: {
        getStatusColor(item) {
            const status = this.preadviseStatuses.find(status => status.text == item ? status.color : '');
            return status.color
        }
    }
};
</script>

<style></style>